<template>

  <div class="breadcrumb">
    <div class="left-part">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>客户中心</el-breadcrumb-item>
        <el-breadcrumb-item>{{title}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>


    <div class="right-part">
      <slot name="right-part"></slot>
    </div>
  </div>


</template>

<script>
  export default {
    name: 'MemberBreadcrumb',
    props: {
      title: String
    }
  }
</script>

<style scoped>
  .breadcrumb {
    position: relative;
    height: 40px;
    width: 100%;
  }

  .left-part {
    position: absolute;
    top: 5px;
  }

  .right-part {
    position: absolute;
    right: 0;
    top: -10px
  }
</style>